import { Observer } from 'mobx-react'
import translationStore from 'stores/translation-store';
import styles from './style';

function Footer(props) {
  const translate = translationStore.translate.bind(translationStore);
  
  const style = {
    backgroundColor: props.tint,
    borderTopColor: props.tint
  }

  return (
    <Observer>
    {
      () => {
        return (
          <footer style={style} className={props.variant}>
            <div className="sections">
              <div className="section">
                <h2 className="title">{translate("footer.column1.title")}</h2>
                <a href={process.env.STATIC_SITE_URL + translate("urls.home")}>{translate("base.home")}</a>
                <a href={process.env.STATIC_SITE_URL + translate("urls.contact")}>{translate("base.faq")}</a>
                <a href={process.env.SITE_URL + translate("urls.pricing") + "?redirect=1&ref=footer"}>{translate("base.pricing")}</a>
                <a href={process.env.STATIC_SITE_URL + translate("urls.aboutus")}>{translate("base.aboutus")}</a>
              </div>
              <div className="section">
                <h2 className="title">{translate("footer.products")}</h2>
                <a href={process.env.SITE_URL + "/premium?redirect=1&ref=footer"}>MyAlbum Premium</a>
                <a href={process.env.STATIC_SITE_URL + translate("urls.make_photo_book")}>{translate("footer.shop")}</a>
                <a href={`${process.env.STATIC_SITE_URL}/${translationStore.language}/share`}>{translate("footer.share")}</a>
                <span>
                  <a href="https://itunes.apple.com/app/id922005321" target="_blank" rel="noreferrer">iPhone</a> / <a href="https://play.google.com/store/apps/details?id=com.myalbum.appv4" target="_blank" rel="noreferrer">Android</a> App
                </span>
              </div>
              <div className="section">
                <h2 className="title">{translate("footer.professional")}</h2>
                <a href={process.env.SITE_URL + "/pro?redirect=1&ref=footer"}>MyAlbum Pro</a>
                <a href={process.env.STATIC_SITE_URL + translate("urls.teamup")}>{translate("footer.teamup")}</a>
                <a href={process.env.STATIC_SITE_URL + translate("urls.contest")}>{translate("footer.contest")}</a>
                <div>
                  <a href={process.env.STATIC_SITE_URL + translate("urls.ngo")} className="inline">{translate("footer.ngo")}</a> <span className="inline">&amp;</span> <a href={process.env.STATIC_SITE_URL + translate("urls.school")} className="inline">{translate("footer.school")}</a>
                </div>
              </div>
              <div className="section">
                <h2 className="title">{translate("footer.follow_us")}</h2>
                <a href="https://www.facebook.com/JoinMyalbum" target="_blank" rel="noreferrer">Facebook</a>
                <a href="https://twitter.com/JoinMyAlbum" target="_blank" rel="noreferrer">Twitter</a>
                <a href="https://discord.gg/7rHk9YUBS9" target="_blank" rel="noreferrer">Discord</a>
                <a href="https://www.instagram.com/myalbum.official/" target="_blank" rel="noreferrer">Instagram</a>
              </div>
            </div>

            <div className="bottom">
              <a href={process.env.STATIC_SITE_URL + translate("urls.legal")}>{translate("footer.legal")}</a>
              <a href={process.env.STATIC_SITE_URL + translate("urls.privacy")} target="_blank" rel="noreferrer">{translate("footer.privacy")}</a>
            </div>

            <style jsx>{styles}</style>
          </footer>
        );
      }
    }
    </Observer>
  );
}

Footer.defaultProps = {
  tint: 'rgba(var(--accentColorDark), 0.1)',
  variant: 'light',
}

Footer.Before = function FooterBefore(props) {
  return (<div style={{flexGrow: 1, minHeight: '6rem', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>{props.children}</div>)
}

export default Footer;