import css from 'styled-jsx/css'

export default css`
  :global(body) {
    background-color: rgba(var(--accentColorLightest), 1);
  }

  .top-bar {
    background-color: white;
    border-bottom: 1px solid rgba(var(--border1));
    padding: 30px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .top-bar:empty {
    display: none;
  }

  .top-bar.bottom {
    position: relative;
    background-color: rgba(255, 255, 255, 0.6);

    border-bottom: 1px solid white;
  }

  .top-bar.bottom:after {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: rgba(var(--border1), 1);
  }
`;

export const pleaseLoginCss = css`
  .please-login {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .security {
    border-radius: 100%;
    background-color: rgba(var(--accentColorDark), 0.3);
    width: 7vw;
    height: 7vw;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .please-login :global(svg) {
    width: 4vw;
    height: 4vw;
    color: white;
  }
`;