import { Button } from 'components/UI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from "@fortawesome/pro-solid-svg-icons/faLock";
import { showAuthentication } from "components/Authentication";
import { pleaseLoginCss } from 'css/myalbums';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

export default function PleaseLogin(props) {
  const router = useRouter();

  useEffect(() => {
    if(router.query.login!==undefined)
    {
      showAuthentication({allowClose:false});
    }else if(router.query.register!==undefined)
    {
      showAuthentication({allowClose:false, screen: "register"});
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="please-login">
      <div className="security">
        <FontAwesomeIcon icon={faLock} />
      </div>

      <Button
        onClick={props.onClick}
        className="filled"
      >{props.buttonLabel}</Button>

      <style jsx>{pleaseLoginCss}</style>
    </div>
  );
}

PleaseLogin.defaultProps = {
  onClick: () =>
  {
    showAuthentication();
  }
}