import PleaseLogin from "components/PleaseLogin/PleaseLogin";
import { MenuBar } from "components/UI";
import Footer from 'components/Footer/Footer';
import { showAuthentication } from "components/Authentication";
import { NextSeo } from "next-seo";

function LoginPage(props) {
  return (
    <>
      <NextSeo
        title={props.access.subject.title}
        description={props.access.subject.subtitle}
        openGraph={{
          title: props.access.subject.title,
          description: props.access.subject.subtitle,
        }}
      />

      <MenuBar
        key="menubar"
        visible={true}
      >
        <div key="left">
          <MenuBar.Back />
        </div>

        <div key="mobile">
          <MenuBar.Back key="shortback" />
          <MenuBar.Cart />
        </div>

        <div key="center">
          <MenuBar.Logo />
        </div>

        <div key="right">
          <MenuBar.Cart />
        </div>
      </MenuBar>

      <Footer.Before>
      {
        // Loginknop als we geen toegang hebben
        <PleaseLogin
          buttonLabel={props.buttonLabel}
          onClick={() => {
            showAuthentication();
          }}
        />
      }
      </Footer.Before>
      <Footer />

    </>
  )
}

export default LoginPage;